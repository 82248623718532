import React, { Component } from 'react';
import NavbarComponent from '../NavBar';

class ProjectComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                
    <NavbarComponent />
                Project Component
            </div>
         );
    }
}
 
export default ProjectComponent;