import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {CssBaseline, Typography, Container }from '@material-ui/core';
import NavbarComponent from './NavBar';


const HomeComponent = (props) => {
  const {path, url} = useRouteMatch()
  console.log("Home", path, url)
  return (
    <main>
      
    <NavbarComponent {...props} />
      <CssBaseline />
      <Container maxWidth="auto">
        <Typography component="div" style={{ backgroundColor: '#cfe8fc', height: '100vh' }} >

          Hey This is home
        </Typography>
      </Container>
    </main>
  );
}

export default HomeComponent;