import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import SignIn from './SignInComponent';
import SignUp from './SingUpComponent';
import Home from './HomeComponent';


/*
*/
const ChallengeForChangeMain = (props) => {
  const { path, url } = props.match;
  console.log("in Challenge", props.match)
  return (
    <div>

      HEY
      <ul>
        <li>
          <Link to={`${url}/signIn`}>Sign In</Link>
        </li>
        <li>
          <Link to={`${url}/signOut`}>Sign Out</Link>
        </li>
        <li>
          <Link to={`${url}/abc`}>Test</Link>
        </li>
      </ul>
      <Switch>
        <Route path={`${path}/signIn`} render={routerProps => <SignIn {...routerProps} />} />
        <Route path={`${path}/signOut`} render={routerProps => <SignUp {...routerProps} />} />
        <Route path={`${path}/abc`} render={routerProps => <h1>Hello</h1>} />
        <Route exact path={path} render={routerProps => <Home {...routerProps} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
}

export default ChallengeForChangeMain;