import React, { Component } from 'react';

class BlogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div>
                BLOG Component
            </div>
         );
    }
}
 
export default BlogComponent;