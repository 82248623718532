import React from 'react';
import { Grid, Container, List, ListItem, Paper } from '@material-ui/core';

import { useStyles, styleDefaults } from './StyleFunctions'
import EducationCard from './EducationCard'


export default function ShowEducation(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" >
      <Paper elevation={3} 
      // className={classes.project}
      style={styleDefaults.project}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={12} style={{
            textAlign: "center"
          }}>
            <p className={classes.heading}>Education</p>
          </Grid>
          <Grid item md={12} style={{
            textAlign: "center"
          }} >
            <List className={classes.box}>
              {EducationInfo.map(value => (

                <ListItem divider>
                  <EducationCard {...value} key={value.url} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}


const EducationInfo = [
  {
    name: "National Institute of Technology, Uttarakhand",
    imageSrc: "/Pictures/NITUK_LOGO.jpg",
    url: "http://nituk.ac.in/",
    timeDuration: "2017-2021",
    subInfo: "B-Tech, Computer Science and Engineering",
    score: "CGPA - 8.2"
  },
  {
    name: "Cambrian Hall, Dehradun",
    imageSrc: "/Pictures/cambrianhall_logo.png",
    url: "http://www.cambrianhall.in/",
    timeDuration: "2015-2017",
    subInfo: "High School",
    score: "ISC Board - 91%",
  }
]