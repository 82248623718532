import React from 'react';
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  withRouter
} from "react-router-dom";
import ChallengeForChangeMain from './challengeForChange/MainComponent';
import SignIn from './challengeForChange/SignInComponent';


const LiveComponentMain = (props) => {
  let { path, url } = props.match;
  console.log('In live', props.match)
  return (
    <>
      <ul>
        <li>
          <Link to={`${url}/challengeForChange`}> Challenge APP</Link>
        </li>
      </ul>
        <Route path={`${path}/challengeForChange`} render={ routerProps => <ChallengeForChangeMain {...routerProps} />} /> 
        <Route exact path={path}>
          <h3>Live Component</h3>
        </Route>

    </>
  );
}

export default LiveComponentMain;