import React from 'react';
import { Grid, Container, List, ListItem, Paper } from '@material-ui/core';

import { useStyles, styleDefaults } from './StyleFunctions';
import ExperienceCard from './ExperienceCard';

export default function ShowExperience(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" >
      <Paper elevation={3} 
      // className={classes.project}
      style={styleDefaults.project}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={12} style={{
            textAlign: "center"
          }}>
            <p className={classes.heading}>Experience</p>
          </Grid>
          <Grid item md={12} style={{
            textAlign: "center"
          }} >
            <List className={classes.box}>
              {ExperienceInfo.map(value => (
                <ListItem divider>
                  <ExperienceCard  {...value} key={value.url} />
                </ListItem>))}

            </List>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}


const ExperienceInfo = [
  
  {
    name: "Software Developer Internship, Indegene",
    info: (<ul>
      <li>Created UI component using react.js</li>
      <li>Experience in Agile model development.</li>
      <li>Design algorithm on Job shop scheduling in python.</li>
      </ul>),
    url: "https://www.linkedin.com/company/indegene/",
    timeDuration: "June,2020 - Aug,2020"
  },
  {
    name: "ACM ICPC Amritapuri Regionals 2019-2020",
    info: "ICPC is an algorithmic programming contest for college students. Teams of three, representing their university.",
    url: "https://icpc.global/ICPCID/KHHK2VB50G4O",
    timeDuration: "26 Dec, 2019 - 30 Dec, 2019"
  },
]