import { makeStyles } from '@material-ui/core/styles';

const primary = "#0077c2"
const light = "#b3e5fc"
const dark = "#263238"
const fontDefault = "robotoCustom"

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    fontFamily: fontDefault,
    backgroundColor: light,
    height: "100%",
    width: "100%",
  },
  paper: {
    padding: theme.spacing(8),
    textAlign: 'center',
    backgroundImage: `linear-gradient(0, ${light}, ${primary})`,
    color: "#000",
    borderRadius: "0px",
    width: "100%",
  },
  heading: {
    fontFamily: fontDefault,
    fontSize: "2em"
  },
  subHeading: {
    fontFamily: fontDefault,
    fontSize: "1.5em",
    padding: "0px",
    margin: "0px"
  },
  text: {
    fontFamily: fontDefault,
    fontSize: "1em"
  },
  subText: {
    fontFamily: fontDefault,
    fontSize: "0.8em",
    marginBottom: "1em",
    // padding: "1em 0px"
  },
  links: {
    color: "#000"
  },
  project: {
    backgroundColor: primary,
    padding: "3em",
    margin: "2em auto",
    color: "#fff",
    justifyContent: "center",
    borderRadius: "2em",
  },
  media: {
    height: "auto"
  },
  card: {
    backgroundColor: dark,
    padding: "10px",
    color: "#fff",
    width: "100%",
    borderRadius: "1em"
  },
  box: {
    width: '100%'
  },
  fontApply: {
    fontFamily: fontDefault,
    color: "#fff"
  },
  footer: {
    backgroundColor: primary,
    padding: "3em",
    margin: "5em 0 0 0",
    color: "#fff",
    justifyContent: "center",
    borderRadius: "2em 2em 0 0",
  }

}));

export const styleDefaults = {
  project: {
    backgroundColor: primary,
    padding: "3em",
    margin: "2em auto",
    color: "#fff",
    justifyContent: "center",
    borderRadius: "2em",
  },
  card: {
    backgroundColor: dark,
    padding: "10px",
    color: "#fff",
    width: "100%",
    borderRadius: "1em"
  },
}