import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import ResumeComponent from './Resume/RsComponent';
import ProjectComponent from './Project/ProjectComponent';
import BlogComponent from './Blog/BlogComponent';
import LiveComponentMain from './Live/MainComponent';
import HomeComponent from './HomeComponent'

const Main = (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/" render={routerProps => <ResumeComponent {...routerProps} />} />
        <Route path="/project" render={routerProps => <ProjectComponent {...routerProps} />} />
        <Route path="/blogs" render={routerProps => <BlogComponent {...routerProps} />} />
        <Route path="/live" render={routerProps => <LiveComponentMain {...routerProps} />} />
        <Route path="/home" render={routerProps => <HomeComponent {...routerProps} />} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}


export default Main;