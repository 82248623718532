import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
// import Main from './Components/MainComponent';
import Main from './Components/MainComponent'
/* TODO
CURRENT
-good UX
-firebase, challenge application
-good stuff
FUTURE
Make blog section

*/
function App() {
  console.log(process.env)
  return (
    <div className="App">
      <Router>
        <React.Fragment>
          <Main />
        </React.Fragment>
      </Router>

    </div>
  );
}

export default App;
