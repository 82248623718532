import React from 'react';
import { Grid, Typography, Link, Container, List, ListItem, Divider, Paper } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { useStyles, styleDefaults } from './StyleFunctions'



export default function ShowProject(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" >
      <Paper elevation={3}
        // className={classes.project}
        style={styleDefaults.project}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center">
          <Grid item md={12} style={{
            textAlign: "center"
          }}>
            <p style={{
              fontSize: "2em"
            }}>Projects</p>
          </Grid>
          <Grid item md={12} style={{
            textAlign: "center"
          }} >
            <List className={classes.box}>
              {ProjectInfo.map(value => (
                <Project {...value} key={value.url} />
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

function Project(props) {
  const classes = useStyles();
  const { name, imageSrc, url, info } = props
  console.log("Project", props)
  return (
    <ListItem divider>
      <Card
        // className={classes.card}
        style={styleDefaults.card}
        >
        <CardActionArea

          href={url} target="_blank" rel="noopener noreferrer">
          <CardContent>
            <p className={classes.heading}>
              {name}
            </p>
            <p className={classes.text} >
              {info}
            </p>
          </CardContent>
          <CardMedia
            component="img"
            className={classes.media}
            src={imageSrc}
            title={name}
          />

        </CardActionArea>
      </Card>
    </ListItem>
  )
}

const ProjectInfo = [
  {
    name: "Chat Room",
    imageSrc: "/Pictures/chatRoom.png",
    url: "https://github.com/ntc255/chat-room",
    info: "It's a sockets based chat server that can be used for exchanging text messages as well as running system commands on the other machine (ex- linux). It's using TCP protocol and RSA cryptosystem for secure communication."
  },
  {
    name: "Car Racing ",
    imageSrc: "car1.png",
    url: "https://github.com/nikeshthapa255/carracing",
    info: "This is a full world training model, to achieve the goal of training an AI to learn car racing game using reinforcement learning. It's is inspired by the World Models by David Ha, Jürgen Schmidhuber."
  },
  {
    name: "Early Warning system on blind turns",
    imageSrc: "/Pictures/blindTurns.jpg",
    url: "https://drive.google.com/file/d/1kUMCP0nQaLEOOxJaHRT0lz-_jFI7I6rD/view",
    info: (<ul><li>It's a warning system for sharp turns in hilly area </li><li>Developed using - (microprocessor)Arduino Uno, Ultra sonic
    sensors and RF sensor </li> <li>Practically tested in Srinagar, Garhwal Roads.</li></ul>)
  }
]

