import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import AppsIcon from '@material-ui/icons/Apps';
import CodeIcon from '@material-ui/icons/Code';
import BookIcon from '@material-ui/icons/Book';
import FaceIcon from '@material-ui/icons/Face';
import { Link } from 'react-router-dom';
import LogoComponent from './UIComponents/LogoComponent';
import { Tooltip } from '@material-ui/core';



export default function NavbarComponent(props) {
  const classes = useStyles();
  console.log('Navbar ', props)
  return (
    <div style={{
      padding: "10px",
      marginBottom: "10px"
    }}>

      <AppBar position="fixed" style={{
        padding: "10px"
      }}>
        <Toolbar >
          <LogoComponent />
          {NavData.map(value => (
            <Tooltip title={value.name}>
              <div className={classes.padding2}>
                <Link to={`${value.url}`} className="link-button" title={value.name}>
                  {value.icon}
                </Link>
              </div>
            </Tooltip>
          ))}
        </Toolbar>
      </AppBar>

      <Toolbar />


    </div>
  );
}

const useStyles = makeStyles({
  padding2: {
    padding: "1px 5px"
  },
})


const NavData = [
  {
    name: "Home",
    url: "/home",
    icon: <HomeIcon fontSize="large" />
  },
  {
    name: "Live",
    url: "/live",
    icon: <AppsIcon fontSize="large" />
  },
  {
    name: "Blogs",
    url: "/blogs",
    icon: <BookIcon fontSize="large" />
  },
  {
    name: "Resume",
    url: "/",
    icon: <FaceIcon fontSize="large" />
  },
  {
    name: "Projects",
    url: "/project",
    icon: <CodeIcon fontSize="large" />
  }
]