import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const LogoComponent = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" align="left" style={{
        color: "#fff",
        fontFamily: "poiretone",
        fontSize: "3em"
      }} > 
        NTC
      </Typography>
    </div>
  );
}

export default LogoComponent;