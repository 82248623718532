import React from 'react';
import Avatar from '@material-ui/core/Avatar';
// import { Row, Item } from '@mui-treasury/components/flex';
// import {
//   Info,
//   p,
//   InfoSubtitle,
//   InfoCaption,
// } from '@mui-treasury/components/info';
// import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';

import { useStyles } from './StyleFunctions'
import { Grid } from '@material-ui/core';


export default function EducationCard(props) {
  const classes = useStyles();
  const { name, url, imageSrc, timeDuration, subInfo, score } = props
  const avatarStyles = {
    height: "50%",
    width: "100%",
    borderRadius: "0.2em",
  };
  return (

    <Grid container className={classes.card} spacing={2}>
      <Grid item sm={1} style={{margin: "auto"}} >
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Avatar
            style={avatarStyles}
            src={imageSrc}
          />

        </a>
      </Grid>
      <Grid item sm={11} style={{textAlign: "left"}} >
        <p className={classes.subHeading}>{name}</p>
        <p className={classes.text}>{subInfo}</p>
        <p className={classes.subText}>{timeDuration}</p>
        <p className={classes.text}>{score}</p>
      </Grid>
    </Grid>
  );
};