import React from 'react';
import { Grid, Typography, Link, Container, List, ListItem, Divider, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import Paper from '@material-ui/core/Paper';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';

import ImageAvatar from '../UIComponents/Avatar';
import CustomIcon from '../UIComponents/CustomIcons';
import { useStyles } from './StyleFunctions'

import ShowProject from './ShowProject'
import AboutMe from './AboutMe';
import ShowEducation from "./ShowEducation"
import ShowExperience from './ShowExperience';


const ResumeComponent = (props) => {
  // const {path, url} = 
  const classes = useStyles();

  return (
    <ScopedCssBaseline className={classes.root}>
      <Header />
      {/* <AboutMe /> */}
      <ShowEducation />
      <ShowExperience />
      <ShowProject />
      <Footer />
    </ScopedCssBaseline >
  );
}

export default ResumeComponent;

function Header(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item md={12} className={classes.paper}>
        <ImageAvatar src="DP1.png" alt="Avatar" />
        <Typography variant="h3" className={classes.heading}>
          Nikesh Thapa
            </Typography>
        <Typography variant="h6" className={classes.heading}>
          Web Developer, Problem solving and AI enthusiastic
      </Typography>
        <Grid direction="row"
          alignItems="center">
          <Typography>
            <Link href="https://www.linkedin.com/in/nikeshthapa/" target="_blank" rel="noopener noreferrer" className={classes.links} >
              <LinkedInIcon fontSize="large" />
            </Link>
            <Link href="https://codeforces.com/profile/nikeshthapa255" target="_blank" rel="noopener noreferrer" className={classes.links}>
              <CustomIcon icon="codeforces" />
            </Link>
            <a href="https://github.com/nikeshthapa255" target="_blank" rel="noopener noreferrer" className={classes.links}>
              <GitHubIcon fontSize="large" />
            </a>
            <Link href="https://www.codechef.com/users/ntc_255" target="_blank" rel="noopener noreferrer" className={classes.links}>
              <CustomIcon icon="codechef" />
            </Link>
          </Typography>
        </Grid>
        <Grid>

        </Grid>
      </Grid>
    </Grid >
  )
}

function Footer(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.footer}
    >
      <div className={classes.subHeading} style={{
        textAlign: "left"
      }}>Contact Me</div>
      <List>
        {FooterInfo.map(({ icon, name, url }) => (
          <ListItem>
            <a href={url} target="_blank" rel="noopener noreferrer" className={classes.links}>
              <ListItemAvatar  >
                <Avatar >
                  {icon}
                </Avatar>
              </ListItemAvatar>
            </a>
            <ListItemText >
              <div className={classes.text}>{name}</div>
            </ListItemText>
          </ListItem>
        ))}

      </List>
    </Grid>
  )
}

const FooterInfo = [
  // {
  //   icon: (<CallIcon />),
  //   name: "8755068189",
  //   url: "tel:+918755068189"
  // },
  {
    icon: (<EmailIcon />),
    name: "nikeshthapa255@gmail.com",
    url: "mailto:nikeshthapa255@gmail.com"
  },
  // {
  //   icon: (<EmailIcon />),
  //   name: "nikesh.cse17@nituk.ac.in",
  //   url: "mailto:nikesh.cse17@nituk.ac.in"
  // }
]
