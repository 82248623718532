/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Icon } from '@material-ui/core';

const CustomIcon = (props) => {
  return (
    <Icon >
      {props.icon in iconList ?
        <img {...iconList[props.icon]} style={styleMedium} /> :
        <img
          src={props.src}
          style={{
            height: "2em",
            width: "2em",
            marginTop: "1em"
          }} />
      }

    </Icon>
  );
}

export default CustomIcon;

const styleMedium = {
  height: "1.4em",
  width: "1.4em",
  border: "0px solid #E1F5FE",
  margin: "0 0.5em"
}


const iconList = {
  'codeforces': {
    src: "/Icons/codeforces.svg",
    alt: "Codeforces"
  },
  'codechef': {
    src: "/Icons/codechef.svg",
    alt: "CodeChef"
  }
}