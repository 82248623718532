import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';

import { useStyles } from './StyleFunctions';


export default function ExperienceCard(props) {
  const { name, url, info, timeDuration } = props
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <ListItem>
        <ListItemText
        >
          <div className={classes.subHeading}>
            {name}
            <IconButton edge="end" aria-label="Details" href={url} target="_blank" rel="noopener noreferrer" >
              <LinkIcon className="link-button-white" />
            </IconButton></div>
          <div className={classes.subText}>{timeDuration}</div>
          <div className={classes.text}>{info}</div>
        </ListItemText>

      </ListItem>
    </div>
  );
}